import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Link, Stack, Text, Grid } from "basis";

import {
  AcceptedCards,
  Container,
  ErrorMessage,
  LoadingMessage,
} from "../../../components";
import {
  FACTOR_NAME,
  prepareWidget,
  useCustomerAuth
} from "../../../core/auth";

import { useThreatMetrix } from "../../../hooks";
import { LoginError, ROUTES, Scene } from "../../../layout/wallet";
import { logger } from "../../../core";
import { customerOktaConfig, lscBaseUrl } from "../../../core/config";
import { MODE } from "../../../core/auth/constants";
import { CustomerOktaContainer } from "../../../layout/shared";
import TokenApplySection from "../../../layout/wallet/components/TokenApplySection";
import { TOKEN_APPLY_SECTION } from "../../../layout/wallet/components/constants";
import { useOrder } from "../../../layout/checkout";
import { MERCHANT_GROUP } from "../../../core/constants";


const Login = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState();
  const [applySection, setApplySection] = useState(true);

  const { startProfile, isLoading } = useThreatMetrix();
  const location = useLocation();
  const widgetRef = useRef();
  const { start } = useCustomerAuth();

  const { loading: fetchingOrder, error, order, params } = useOrder();

  useEffect(() => {
    startProfile();
  }, []);
  useEffect(() => {
    if (fetchingOrder) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!widgetRef.current) {
      return;
    }

    if (!order?.transactionId) {
      return;
    }

    if (loginError) {
      return;
    }

    start();

    const widget = prepareWidget({
      oktaConfig: customerOktaConfig,
      mode: MODE.CUSTOMER,
      targetElement: widgetRef.current,
      title: "Login",
      usernamePlaceholder: "Latitude ID",
      redirectURL: `${ROUTES.TOKEN_CONSENT}${location.search}`,
      allowedFactors: [FACTOR_NAME.SMS,FACTOR_NAME.EMAIL],
      afterAuth: () => setAuthenticated(true),
      afterRender: () => setApplySection(false),
      onError: (code, description) => {
        logger.error(`Token login error. ${code}: ${description}`);

        setLoginError(code);
      },
    });
    return () => {
      widget.off();
      widget.remove();
    };
  }, [fetchingOrder, location.search, params, order, error, start, loginError, applySection]);

  if (fetchingOrder || isAuthenticated || isLoading) {
    return (
      <Scene >
        <LoadingMessage />
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }

  return (
    <Scene merchantLogoURL={order?.urlLogo} country={order?.country} >
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Link href={order?.urlCancel} newTab={false}>
            &lt; Back to cart
          </Link>
          <Text align="left" textStyle="heading4">
            Pay with Latitude
          </Text>
        </Stack>
      </Container>



      <Container bg={order?.merchantId !== '000422456' && 'white'} margin={order?.merchantId === '000422456' && "4 0"} maxWidth={1100} shaadow={order?.merchantId !== '000422456' && true} >
        <Grid preset="page" rowsGap={7}>
          {applySection === true && order?.merchantId === '000422456' && (<TokenApplySection applyHeader={TOKEN_APPLY_SECTION.APPLY_HEADER} applyDescription={TOKEN_APPLY_SECTION.APPLY_DESCRIPTION} applyButtonText={TOKEN_APPLY_SECTION.APPLY_BUTTON_TEXT} applyCardDescription={TOKEN_APPLY_SECTION.APPLY_CARD_DESCRIPTION} applyApprovedHeader={TOKEN_APPLY_SECTION.APPLY_APPROVED_HEADER} applyButton={TOKEN_APPLY_SECTION.APPLY_BUTTON} />)}
          <Grid.Item colSpan="all" colSpan-md={applySection === true && order?.merchantId === '000422456' ? "4-12" : ""} colSpan-lg={applySection === true && order?.merchantId === '000422456' ? "5-12" : ""}>
            <Container bg="white" margin="4 0" padding="10 6" maxWidth={400}>
              <Stack gap="4">
                <Text align="left" textStyle="heading4">
                  {applySection === true ? 'Already a customer? Login' : 'Verify your mobile'}
                </Text>

                {applySection === true ? (
                  <Text textStyle="body2" align="left">

                    Enter your Latitude ID to receive an one time verification code.The code will be sent to your contact details linked to your Latitude account.
                  </Text>
                ) : ""}

                {loginError && (
                  <LoginError
                    errorCode={loginError}
                  >
                  </LoginError>
                )}

                <CustomerOktaContainer ref={widgetRef} />
                <AcceptedCards
                  country={order?.country}
                  showAppleAUCreditLine={
                    order?.merchantGroup === MERCHANT_GROUP.APPLE_AU
                  }
                />

                <Link
                  href={`${ROUTES.TOKEN_CONSENT}${location.search}`}
                  newTab={false}
                >
                  Back to login
                </Link>

                <Link href={`${lscBaseUrl}/faq`} newTab>
                  Need help signing in?
                </Link>
              </Stack>
            </Container>
          </Grid.Item>
        </Grid>
      </Container>
    </Scene>
  );
};

export default Login;
